import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getJobData } from '../../actions/jobActions'
import { Message, Loader } from '../componentLib'
import { RBA_ADMIN, RBA_CAD } from '../../utils/rba'

export const WeeklyData = (userRole) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getJobData())
  }, [dispatch])

  const jobData = useSelector((state) => state.jobData)
  const { loadingWeekly, errorWeekly, dataWeekly } = jobData
  console.log({ userRole })

  //Interval function to update periodically
  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  const delay = RBA_CAD.indexOf(userRole) >= 0 ? 5 * 1000 : 5 * 60 * 60 * 1000

  useInterval(() => {
    // Make the request here
    console.log('call get job data')
    dispatch(getJobData())
  }, delay)

  return (
    <div>
      {loadingWeekly ? (
        <Loader />
      ) : errorWeekly ? (
        <Message variant="danger">{errorWeekly}</Message>
      ) : dataWeekly ? (
        <>
          <div className="text-center">
            <i className="fa-duotone fa-calendar-week" /> Jobs{' '}
            <span className="mark-a">
              last week: <strong>{dataWeekly.numLastWeek}</strong>
            </span>{' '}
            Jobs{' '}
            <span className="mark-b">
              this week: <strong>{dataWeekly.numThisWeek}</strong>
            </span>{' '}
            <br />
            {RBA_ADMIN.indexOf(userRole.userRole) >= 0 && (
              <>
                <i className="fa-duotone fa-sack-dollar" />
                Sales{' '}
                <span className="mark-c">
                  last week: <strong>${(dataWeekly.salesLastWeek * 0.9).toFixed(0)}</strong>
                </span>{' '}
                Sales{' '}
                <span className="mark-d">
                  this week: <strong>${(dataWeekly.salesThisWeek * 0.9).toFixed(0)}</strong>
                </span>
                <br />
              </>
            )}
            <i className="fa-duotone fa-calendar-days" /> Jobs{' '}
            <span className="mark-e">
              last month: <strong>{dataWeekly.numLastMonth}</strong>
            </span>{' '}
            Jobs{' '}
            <span className="mark-f">
              this month: <strong>{dataWeekly.numThisMonth}</strong>
            </span>
            {/* Admin view of CAD */}
            {RBA_ADMIN.indexOf(userRole.userRole) >= 0 && (
              <>
                {' '}
                <hr />
                Tela{' '}
                <span className="mark-blue-a">
                  last week: <strong>{dataWeekly.telaLastWeek}</strong>{' '}
                </span>
                Tela{' '}
                <span className="mark-blue-b">
                  this week: <strong>{dataWeekly.telaThisWeek}</strong>
                </span>
                <br />
                Tela{' '}
                <span className="mark-blue-c">
                  last month: <strong>{dataWeekly.telaLastMonth}</strong>{' '}
                </span>
                Tela{' '}
                <span className="mark-blue-d">
                  this month: <strong>{dataWeekly.telaThisMonth}</strong>
                </span>
              </>
            )}
          </div>

          {/* Status Panel */}
          {RBA_CAD.indexOf(userRole.userRole) >= 0 && (
            <>
              <hr />
              <div className="text-center">
                <strong>Current Status</strong>
                {RBA_ADMIN.indexOf(userRole.userRole) >= 0 && (
                  <div className="my-2">
                    <i className="fa-duotone fa-folder-open" />{' '}
                    <a href="/jobs?status=Submitted">
                      Submitted:{' '}
                      <strong>
                        {dataWeekly.status.find((x) => x.status === 'Submitted')
                          ? dataWeekly.status.find((x) => x.status === 'Submitted')['count']
                          : 0}
                      </strong>
                    </a>
                  </div>
                )}
                <div className="my-2">
                  <i className="fa-duotone fa-check-to-slot" />{' '}
                  <a href="/jobs?status=Validated">
                    Validated:{' '}
                    <strong>
                      {dataWeekly.status.find((x) => x.status === 'Validated')
                        ? dataWeekly.status.find((x) => x.status === 'Validated')['count']
                        : 0}
                    </strong>
                  </a>
                </div>
                <div className="my-2">
                  <i className="fa-duotone fa-briefcase" />{' '}
                  <a href="/jobs?status=Working">
                    Working:{' '}
                    <strong>
                      {dataWeekly.status.find((x) => x.status === 'Working')
                        ? dataWeekly.status.find((x) => x.status === 'Working')['count']
                        : 0}
                    </strong>
                  </a>
                </div>
                <div className="my-2">
                  <i className="fa-duotone fa-user-magnifying-glass" />{' '}
                  <a href="/jobs?status=Review">
                    Review:{' '}
                    <strong>
                      {dataWeekly.status.find((x) => x.status === 'Review')
                        ? dataWeekly.status.find((x) => x.status === 'Review')['count']
                        : 0}
                    </strong>
                  </a>
                </div>
                <div className="my-2">
                  <i className="fa-duotone fa-pen-to-square" />{' '}
                  <a href="/jobs?status=Correction">
                    Correction:{' '}
                    <strong>
                      {dataWeekly.status.find((x) => x.status === 'Correction')
                        ? dataWeekly.status.find((x) => x.status === 'Correction')['count']
                        : 0}
                    </strong>
                  </a>
                </div>
                <div className="my-2">
                  <i className="fa-duotone fa-marker" />{' '}
                  <a href="/jobs?status=Revision">
                    Revision:{' '}
                    <strong>
                      {dataWeekly.status.find((x) => x.status === 'Revision')
                        ? dataWeekly.status.find((x) => x.status === 'Revision')['count']
                        : 0}
                    </strong>
                  </a>
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
    </div>
  )
}
