import axios from 'axios'
import {
  JOBS_GET_ALL_REQUEST,
  JOBS_GET_ALL_SUCCESS,
  JOBS_GET_ALL_FAIL,
  JOB_GET_DETAIL_SUCCESS,
  JOB_GET_DETAIL_FAIL,
  JOB_GET_DETAIL_REQUEST,
  JOB_CREATE_REQUEST,
  JOB_CREATE_SUCCESS,
  JOB_CREATE_FAIL,
  JOB_UPDATE_STATUS_REQUEST,
  JOB_UPDATE_STATUS_SUCCESS,
  JOB_UPDATE_STATUS_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_SUCCESS,
  JOB_DELETE_FAIL,
  JOB_INVOICE_REQUEST,
  JOB_INVOICE_SUCCESS,
  JOB_INVOICE_FAIL,
  JOB_DATA_REQUEST,
  JOB_DATA_SUCCESS,
  JOB_DATA_FAIL,
  BILLING_DATA_REQUEST,
  BILLING_DATA_SUCCESS,
  BILLING_DATA_FAIL,
  DASHBOARD_DATA_REQUEST,
  DASHBOARD_DATA_SUCCESS,
  DASHBOARD_DATA_FAIL,
} from './constants'
import { logout } from './userActions'

//CREATE JOB
export const createJob = (job) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    console.log('in action')
    console.log(job)
    const { data } = await axios.post(`/api/v1/jobs`, job, config)

    dispatch({
      type: JOB_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: JOB_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//Duplicate Job by ID
export const duplicateJob = (jobId, jobName) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      `/api/v1/jobs/${jobId}/duplicate`,
      { jobName: jobName },
      config
    )

    dispatch({
      type: JOB_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: JOB_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//GET ALL JOBS INDEX
// numJobs default to 0, which results in all jobs being pulled
export const getAllJobs =
  (
    numJobs = 0,
    showCad = true,
    showNonCad = false,
    activeOnly = false,
    mineOnly = false,
    status = false
  ) =>
  async (dispatch, getState) => {
    try {
      console.log('job num:')
      console.log(numJobs)
      dispatch({
        type: JOBS_GET_ALL_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/v1/jobs?num=${numJobs}&showCad=${showCad}&showNonCad=${showNonCad}&activeOnly=${activeOnly}&mineOnly=${mineOnly}&status=${status}`,
        config
      )

      dispatch({
        type: JOBS_GET_ALL_SUCCESS,
        payload: data,
      })
      //localStorage.setItem('jobIndex', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: JOBS_GET_ALL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

//GET JOB DETAILS SHOW
export const getJobById = (jobId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_GET_DETAIL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/jobs/${jobId}`, config)

    dispatch({
      type: JOB_GET_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: JOB_GET_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//DELETE JOB DESTROY
export const deleteJobById = (jobId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(`/api/v1/jobs/${jobId}`, config)

    dispatch({
      type: JOB_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: JOB_DELETE_FAIL,
      payload: message,
    })
  }
}

//UPDATE JOB STATUS (non standard REST)
export const updateJobStatus =
  (jobId, type, level = 0) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: JOB_UPDATE_STATUS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      let data
      if (type === 'validate') {
        let response = await axios.patch(
          `/api/v1/jobs/${jobId}/validate`,
          { level },
          config
        )
        data = response.data
      } else if (type === 'assign') {
        let response = await axios.patch(
          `/api/v1/jobs/${jobId}/assign`,
          {},
          config
        )
        data = response.data
      } else if (type === 'unassign') {
        let response = await axios.patch(
          `/api/v1/jobs/${jobId}/assign`,
          { unassign: true },
          config
        )
        data = response.data
      } else if (type === 'release') {
        let response = await axios.patch(
          `/api/v1/jobs/${jobId}/release`,
          { level },
          config
        )
        data = response.data
      } else if (type === 'maxRevs') {
        let response = await axios.patch(
          `/api/v1/jobs/${jobId}/maxRevs`,
          { level },
          config
        )
        data = response.data
      } else if (type === 'quoteStatus') {
        let response = await axios.patch(
          `/api/v1/jobs/${jobId}/quoteStatus`,
          { level },
          config
        )
        data = response.data
      } else if (type === 'productionStatus') {
        let response = await axios.patch(
          `/api/v1/jobs/${jobId}/productionStatus`,
          { level },
          config
        )
        console.log(`productionStatus: ${level}`)
        data = response.data
      } else if (type === 'rush') {
        let response = await axios.patch(
          `/api/v1/jobs/${jobId}/rush`,
          { level },
          config
        )
        data = response.data
      }

      dispatch({
        type: JOB_UPDATE_STATUS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: JOB_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

//Invoice JOB
export const invoiceJob =
  (jobId, invoiceType) => async (dispatch, getState) => {
    try {
      dispatch({
        type: JOB_INVOICE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.patch(
        `/api/v1/jobs/${jobId}/invoice`,
        { invoiceType: invoiceType },
        config
      )

      dispatch({
        type: JOB_INVOICE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: JOB_INVOICE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

//GET JOB DATA
export const getJobData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_DATA_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/admin/data/weekly`, config)
    console.log(data)

    dispatch({
      type: JOB_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: JOB_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//GET BILLING DATA
export const getBillingData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BILLING_DATA_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/admin/data/billing`, config)
    console.log(data)

    dispatch({
      type: BILLING_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BILLING_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//GET DASHBOARD DATA
export const getDashboardData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DASHBOARD_DATA_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/admin/data/dashboard`, config)
    console.log(data)

    dispatch({
      type: DASHBOARD_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DASHBOARD_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
