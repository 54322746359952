import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Button } from 'react-bootstrap'
import Moment from 'react-moment'
import 'moment-timezone'
import Linkify from 'react-linkify'

import { updateJobStatus } from '../../actions/jobActions'
import { getNotesByJobId } from '../../actions/noteActions'
import { Message, Loader, NoteCreateModal } from '../componentLib'
import { RBA_CAD, RBA_ADMIN, RBA_SUPER } from '../../utils/rba'

export const NoteIndex = ({ jobId, jobRev, jobCorr, jobStatus, cadId }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(getNotesByJobId(jobId))
    }
  }, [dispatch, userInfo, jobId])

  const noteIndex = useSelector((state) => state.noteIndex)
  const { loading, error, notes } = noteIndex

  useEffect(() => {
    if (notes) {
      if (jobStatus === 'Review') {
        const allNoteFiles = notes.map((note) => note.files)
        const draftFiles = allNoteFiles[0]
          ?.filter(
            (file) =>
              file.fileType === 4 &&
              file.jobRev === jobRev &&
              file.jobCorr === jobCorr
          )
          .map((file) => file._id)

        draftFiles && setReleaseFiles(draftFiles)
        console.log(`id for release:${draftFiles}`)
      }
    }
  }, [notes, jobCorr, jobRev, jobStatus])

  //Note Modal LOCAL SCOPE
  const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false)
  const [noteAction, setNoteAction] = useState('none')
  const [releaseFiles, setReleaseFiles] = useState('')

  const handleCorrection = (e) => {
    e.preventDefault()
    setNoteAction('Correction')
    setReviewModalIsOpen(!reviewModalIsOpen)
  }

  const handleRelease = (e) => {
    e.preventDefault()
    dispatch(updateJobStatus(jobId, 'release', releaseFiles))
    setReviewModalIsOpen(false)
  }

  const handleRfiResponse = (e) => {
    e.preventDefault()
    console.log('rfi response modal open req')
    setNoteAction('RfiResponse')
    setReviewModalIsOpen(!reviewModalIsOpen)
  }

  const handleRevisionResponse = (e) => {
    e.preventDefault()
    console.log('rev response modal open req')
    setNoteAction('RevisionResponse')
    setReviewModalIsOpen(!reviewModalIsOpen)
  }

  //Interval function to update periodically
  const useInterval = (callback, delay) => {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      const tick = () => {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  const refreshInterval =
    RBA_CAD.indexOf(userInfo.role) >= 0 ? 5 * 1000 : 5 * 60 * 1000

  useInterval(() => {
    // Make the request here
    dispatch(getNotesByJobId(jobId))
  }, refreshInterval)

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : notes ? (
        <>
          {RBA_ADMIN.indexOf(userInfo?.role) >= 0 && (
            <div className="mb-2 text-muted">
              DEBUG: Job Rev: {jobRev}
              Job Corr: {jobCorr}
              User role: {userInfo?.role}
            </div>
          )}

          {reviewModalIsOpen && (
            <NoteCreateModal jobId={jobId} openState noteAction={noteAction} />
          )}

          {notes.map((note) => (
            <>
              {note.text === '' && RBA_CAD.indexOf(userInfo?.role) === -1 ? (
                ''
              ) : (
                <Card className="card-note mb-2" key={`card-${note._id}`}>
                  <Card.Title as="h5-card" className="mb-2" key={note._id}>
                    <strong>{note.author?.name}</strong> (
                    {note.owner?.companyName})
                    <Moment className="float-end" format="ddd MMM Do hh:mm a">
                      {note.timeIn}
                    </Moment>
                    <Card.Subtitle
                      className="mb-2 text-muted"
                      key={`sub-${note._id}`}
                    >
                      {note.flagCad && <em> CAD only </em>}
                      {note.flagReview && <em>For REVIEW</em>}
                      {note.flagCorrection && <em>For CORRECTIONS</em>}
                      {note.flagRelease && <em>JOB RELEASE</em>}
                      {note.flagRevision && <em>REVISION</em>}
                      {note.flagRfi && <em>RFI</em>}
                      {note.flagRfiResponse && <em>Response to RFI</em>}
                      {note.flagRevisionResponse && (
                        <em>Response to Revision</em>
                      )}
                    </Card.Subtitle>
                  </Card.Title>
                  <Card.Body className="pb-2 pt-2" key={`body-${note._id}`}>
                    {RBA_SUPER.indexOf(userInfo?.role) >= 0 && (
                      <div className="mb-2 text-muted">
                        Debug: Rev: {note.jobRev} Corr: {note.jobCorr}
                      </div>
                    )}
                    <Linkify>{note.text}</Linkify>
                    <hr></hr>
                    {note.flagRevision &&
                      jobStatus === 'Revision' &&
                      jobRev - 1 === note.jobRev &&
                      RBA_CAD.indexOf(userInfo?.role) >= 0 && (
                        <button
                          className="mb-3 btn btn-warning"
                          onClick={handleRevisionResponse}
                        >
                          <i className="fas fa-times" /> Mark not Revision
                        </button>
                      )}
                    {note.flagRfi && jobStatus === 'RFI' && (
                      <Button
                        className="mb-3"
                        variant="primary"
                        onClick={handleRfiResponse}
                      >
                        <i className="fas fa-reply" /> Respond to RFI
                      </Button>
                    )}
                    {note.flagReview &&
                      RBA_ADMIN.indexOf(userInfo?.role) >= 0 &&
                      jobStatus === 'Review' &&
                      jobRev === note.jobRev &&
                      jobCorr === note.jobCorr && (
                        <Row>
                          <Button
                            className="mb-3"
                            variant="warning"
                            onClick={handleCorrection}
                          >
                            <i className="fas fa-reply" /> Return for
                            Corrections
                          </Button>
                          <Button
                            className="mb-3"
                            variant="danger"
                            onClick={handleRelease}
                          >
                            <i className="fas fa-reply" /> Release Job
                          </Button>
                        </Row>
                      )}
                    {/* pull back for CAD if is owner */}
                    {note.flagReview &&
                      userInfo?.role === 'cad' &&
                      cadId == userInfo._id &&
                      jobStatus === 'Review' &&
                      jobRev === note.jobRev &&
                      jobCorr === note.jobCorr && (
                        <Row>
                          <Button
                            className="mb-3"
                            variant="warning"
                            onClick={handleCorrection}
                          >
                            <i className="fas fa-reply" /> Pull back for
                            corrections
                          </Button>
                        </Row>
                      )}

                    {note.flagReview && RBA_CAD.indexOf(userInfo?.role) === -1
                      ? ''
                      : note.files.map((file) => (
                          <p className="mb-1">
                            <a
                              href={file.url}
                              key={note._id + file}
                              without
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                          </p>
                        ))}
                  </Card.Body>
                </Card>
              )}
            </>
          ))}
        </>
      ) : (
        ''
      )}
    </>
  )
}
