import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateJobStatus, invoiceJob } from '../../actions/jobActions'
import { NoteCreateModal, JobDupeModal } from '../componentLib.js'
import { JobDuplicate } from './JobDuplicate'

export const JobValidate = ({ jobId, admin, billingStatusCurrent, jobName, jobStatusCurrent, isCad = false }) => {
  // let cardWidth
  // admin ? (cardWidth = '26rem') : (cardWidth = '24rem')
  const dispatch = useDispatch()

  const validateHandler = (e) => {
    console.log(e)
    console.log(e.target)
    console.log(jobId)
    dispatch(updateJobStatus(jobId, 'validate', e.target.value))
  }

  const invoiceHandler = (jobId, invoiceType) => {
    dispatch(invoiceJob(jobId, invoiceType))
  }

  //Note Modal
  const [rfiModalIsOpen, setRfiModalIsOpen] = useState(false)
  const rfiHandler = (e) => {
    setRfiModalIsOpen(!rfiModalIsOpen)
  }

  return (
    <>
      {isCad == true ? (
        <Card className="px-4 mx-2 text-center">
          <div>
            {jobStatusCurrent !== 'Released' && (
              <Button className="m-3 btn btn-secondary" block onClick={rfiHandler}>
                <i className="fas fa-question-circle" /> Submit RFI{' '}
              </Button>
            )}
            {admin && <JobDuplicate jobId={jobId} jobName={jobName} />}
            {rfiModalIsOpen && <NoteCreateModal jobId={jobId} openState noteAction="Rfi" />}
          </div>
        </Card>
      ) : (
        <Card className="px-4 mx-2 text-center">
          {!admin && <strong>Validate</strong>}
          <div className="align-items-center">
            <Button className="btn btn-warning m-1" value="1" onClick={validateHandler}>
              1
            </Button>

            <Button className="btn btn-warning m-1" value="2" onClick={validateHandler}>
              2
            </Button>

            <Button className="btn btn-warning m-1" value="3" onClick={validateHandler}>
              3
            </Button>

            <Button className="btn btn-warning m-1" value="4" onClick={validateHandler}>
              4
            </Button>
            <Button className="btn btn-warning m-1" value="5" onClick={validateHandler}>
              5
            </Button>
            {admin && billingStatusCurrent === 'Incomplete' && (
              <>
                <Button
                  onClick={(e) => {
                    invoiceHandler(jobId, 'Demo')
                  }}
                  //variant='success'
                  className="mx-3 my-1 btn btn-success"
                >
                  <i className="fas fa-toilet-paper"></i> Set to Demo
                </Button>
              </>
            )}
            {admin && billingStatusCurrent === 'Demo' && (
              <>
                <Button
                  onClick={(e) => {
                    invoiceHandler(jobId, 'Incomplete')
                  }}
                  //variant='success'
                  className="mx-3 my-1 btn btn-success"
                >
                  <i className="fas fa-dollar-sign"></i> Set to Bill
                </Button>
              </>
            )}
          </div>
          <div>
            {jobStatusCurrent !== 'Released' && (
              <Button className="m-3 btn btn-secondary" block onClick={rfiHandler}>
                <i className="fas fa-question-circle" /> Submit RFI{' '}
              </Button>
            )}
            {admin && <JobDuplicate jobId={jobId} jobName={jobName} />}
            {rfiModalIsOpen && <NoteCreateModal jobId={jobId} openState noteAction="Rfi" />}
          </div>
        </Card>
      )}
    </>
  )
}
